  <template>
  <v-col>
    <v-row>
      <v-col>
        <number-field
          :disabled="!document.canUpdate"
          :number.sync="document.invoicesCount"
          :label="$t('t.InvoiceCount')"
          class="ml-2 mr-5"
        />
      </v-col>
      <v-col>
        <date-picker-menu
          v-model="targetDate"
          :label="$t('t.TargetDate')"
          :disabled="!document.canUpdate"
        />
      </v-col>
      <v-col>
        <amount-field
          :disabled="!document.canUpdate"
          :amount.sync="document.allocationAnnouncementTotalAmount"
          :label="$t('t.AnnouncementAmount')"
          :currency.sync="this.document.currency"
        />
      </v-col>
      <v-col>
        <amount-field
          :disabled="!document.canUpdate"
          :amount.sync="document.invoicesTotalBalance"
          :label="$t('t.Balance')"
          :currency.sync="this.document.currency"
        />
      </v-col>
      <v-col>
        <amount-field
          :disabled="!document.canUpdate"
          :amount.sync="diffAmount"
          :label="$t('t.Difference')"
          :currency.sync="this.document.currency"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  components: {
    DatePickerMenu: () => import('@/components/date-picker-menu'),
    AmountField: () => import('@/components/amount-field'),
    NumberField: () => import('@/components/number-field')
  },
  computed: {
    targetDate () {
      return this.document.targetDate
    },
    diffAmount () {
      return (this.document.invoicesTotalBalance ?? 0) - (this.document.allocationAnnouncementTotalAmount ?? 0)
    }
  },
  props: {
    document: Object
  }
}
</script>

<style lang="stylus" scoped></style>
